
import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from 'bootstrap';
import { useHistory, useLocation } from "react-router-dom";
import clientHttp from '../../components/ClientHttp';

import OrderStatusList from "./OrderStatusList"
import Pagination from '../../components/Pagination';
import queryString from 'query-string';

import * as XLSX from 'xlsx/xlsx.mjs';
import CaptureOrderList from './CaptureOrderList';
import { getBankList, getOrderStatusList } from '../../components/DataList';
import GenerateBankFile from './GenerateBankFile';


export default function OrderStatusPage() {
    // auto load bank summary when first come
    const currentDate = new Date();
    const [fromDate, setFromDate] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
    const [toDate, setToDate] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));
    const [bank, setBank] = useState('')
    const [bankStatus, setBankStatus] = useState('')
    const [orderStatus, setOrderStatus] = useState('')
    const [validateStatus, setValidateStatus] = useState('')
    const [aboNo, setAboNo] = useState('')
    const [orderNo, setOrderNo] = useState('')
    const [fileId, setFileId] = useState('')
    const [data, setData] = useState(null);
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingExport, setIsLoadingExport] = useState(false)

    // on/off order status list and capture payment list
    const [isShowOrderList, setIsShowOrderList] = useState(true);
    const [isShowCaptureOrderList, setIsShowCaptureOrderList] = useState(false)
    const [isShowGenerateBankFile, setIsShowGenerateBankFile] = useState(false)

    // change page
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0)
    const [pageSize, setPageSize] = useState(50);
    const onPageChange = (page) => {
        setCurrentPage(page);
        console.log('select page: ' + page)
        searchSubmit(page)
    }
    let history = useHistory()

    async function searchSubmit(page) {
        setCurrentPage(page);
        setIsLoading(true)
        let isSuccess = false
        // clear data
        setData(null)
        setSelectedOrderList([])
        setCaptureOrderList([])
        setGenerateBankFileList([])
        // toggle order list
        setIsShowOrderList(true)
        setIsShowCaptureOrderList(false)
        setIsShowGenerateBankFile(false)

        console.log('call search api with parameters:' + currentPage, pageSize)

        // set token to header
        const startDate = fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate()
        const endDate = toDate.getFullYear() + '-' + (toDate.getMonth() + 1) + '-' + toDate.getDate()
        let _orderstatus = ''
        let _validatestatus = validateStatus
        if (validateStatus === 'CANCELLED') {
            _orderstatus = validateStatus
            _validatestatus = ''
        }
        const formData = {
            startDate: startDate,
            endDate: endDate,
            bank: bank,
            bankStatus: bankStatus,
            orderStatus: _orderstatus, // default '', use only validate status to check status of order
            validateStatus: _validatestatus,
            aboNo: aboNo,
            orderNo: orderNo,
            fileId: fileId,
            page: page,
            pageSize: pageSize
        }
        console.log(formData)
        let token = localStorage.getItem('token')
        await clientHttp.post('autorenew/v1/order_status', formData, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then(response => {
                console.log('response status: ' + response.status)
                if (response.data.status === 'success') {
                    console.log('display order status')
                    setTotal(response.data.total)
                    setData(response.data.data)
                    isSuccess = true
                } else {
                    console.log('get order_status failed: ' + response.data.message)
                    setError(response.data.message)
                }

            }).catch(err => {
                console.log('error: ', err.message)
                if (err.response.status === 401 || err.response.status === 403) {
                    console.log('no authorize, redirect to login page')
                    history.push(process.env.REACT_APP_LOGIN_URI)
                } else {
                    console.log('get order_status failed: ' + err.message)
                    setError(err.message)
                }
            })

        if (!isSuccess) {
            let errorMessageModal = new Modal('#errorMessageModal')
            errorMessageModal.show()
        }
        setIsLoading(false)
    }

    const selectBank = (e) => { setBank(e.target.value) }
    const selectBankStatus = (e) => { setBankStatus(e.target.value) }
    //const selectOrderStatus = (e) => { setOrderStatus(e.target.value) }
    const selectValidateStatus = (e) => { setValidateStatus(e.target.value) }
    const changeAboNo = (e) => { setAboNo(e.target.value) }
    const changeOrderNo = (e) => { setOrderNo(e.target.value) }
    const changeFileId = (e) => { setFileId(e.target.value) }
    const selectPageSize = (e) => { setPageSize(e.target.value) }

    async function exportExcel() {
        console.log('call export excel api with parameters')
        setIsLoadingExport(true)
        let isSuccess = false

        // set token to header
        const startDate = fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate()
        const endDate = toDate.getFullYear() + '-' + (toDate.getMonth() + 1) + '-' + toDate.getDate()
        let _orderstatus = ''
        let _validatestatus = validateStatus
        if (validateStatus === 'CANCELLED') {
            _orderstatus = validateStatus
            _validatestatus = ''
        }
        const formData = {
            startDate: startDate,
            endDate: endDate,
            bank: bank,
            bankStatus: bankStatus,
            orderStatus: _orderstatus,
            validateStatus: _validatestatus,
            aboNo: aboNo,
            orderNo: orderNo,
            fileId: fileId,
            page: 0, // all data
            pageSize: 0
        }
        let token = localStorage.getItem('token')
        await clientHttp.post('autorenew/v1/order_status', formData, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then(response => {
                console.log('response status: ' + response.status)
                if (response.data.status === 'success') {
                    console.log('export order status')
                    const curdate = new Date()
                    const exportFilename = 'orderstatus_' + curdate.getFullYear() + '_' + curdate.getMonth() + '_' + curdate.getDate() + '.xlsx'
                    const worksheet = XLSX.utils.json_to_sheet(response.data.data);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheet, "Order");
                    XLSX.writeFile(workbook, exportFilename);
                    isSuccess = true
                } else {
                    console.log('get order_status failed: ' + response.data.message)
                    setError(response.data.message)
                }

            }).catch(err => {
                console.log('error: ', err.message)
                if (err.response.status === 401 || err.response.status === 403) {
                    console.log('no authorize, redirect to login page')
                    history.push(process.env.REACT_APP_LOGIN_URI)
                } else {
                    console.log('get order_status failed: ' + err.message)
                    setError(err.message)
                }
            })

        if (!isSuccess) {
            let errorMessageModal = new Modal('#errorMessageModal')
            errorMessageModal.show()
        }
        setIsLoadingExport(false)
    }

    // manage order list and capture
    const [selectedOrderList, setSelectedOrderList] = useState([])
    const [captureOrderList, setCaptureOrderList] = useState([])
    async function captureOrder() {
        console.log('Capture order id: ' + selectedOrderList)
        // display order to confirm
        let orderList = []
        for (let i = 0; i < selectedOrderList.length; i++) {
            console.log('find order id: ' + selectedOrderList[i])
            let ele = data.find((order) => order.id === parseInt(selectedOrderList[i]));
            if (ele) {
                ele.result = '' // clear previous result
                orderList.push(ele)
            }
        }

        console.log(orderList)
        setCaptureOrderList(orderList)

        // toggle order list
        setIsShowOrderList(false)
        setIsShowCaptureOrderList(true)
    }

    function cancelCaptureOrder() {
        setSelectedOrderList([])
        setCaptureOrderList([]) // clear selected list
        setIsShowOrderList(true)
        setIsShowCaptureOrderList(false)
    }

    // section: generate bank file
    const [generateBankFileList, setGenerateBankFileList] = useState([])
    async function generateOrderToBankFile() {
        console.log('Selected order id: ' + selectedOrderList)
        // display order to confirm and filter only bank status is ERROR
        let orderList = []
        for (let i = 0; i < selectedOrderList.length; i++) {
            console.log('find order id: ' + selectedOrderList[i])
            let ele = data.find((order) => order.id === parseInt(selectedOrderList[i]) && order.bankstatus === 'ERROR');
            if (ele) {
                ele.result = '' // clear previous result
                orderList.push(ele)
            }
        }

        console.log(orderList)
        setGenerateBankFileList(orderList)

        // toggle order list
        setIsShowOrderList(false)
        setIsShowGenerateBankFile(true)
    }

    function cancelGenerateBankFile() {
        setSelectedOrderList([])
        setGenerateBankFileList([]) // clear selected list
        setIsShowOrderList(true)
        setIsShowGenerateBankFile(false)
    }


    // load data list for dropdown
    const [bankNameList, setBankNameList] = useState([])
    //const [orderStatusList, setOrderStatusList] = useState([])
    const { search } = useLocation()
    // use for check parse parameters from url
    const [passParameters, setPassParameters] = useState()

    useEffect(() => {
        // get bank list
        (async () => {
            console.log('load bank list')
            setBankNameList(await getBankList())

            //console.log('load order status list')
            //setOrderStatusList(await getOrderStatusList())

            // set default search values
            if (search) {
                const parsed = queryString.parse(search);
                console.log(parsed);
                if (parsed) {
                    if (parsed.startdate) {
                        let ary = parsed.startdate.split("-")
                        setFromDate(new Date(ary[0], ary[1], ary[2]))
                    }
                    if (parsed.enddate) {
                        let ary = parsed.enddate.split("-")
                        setToDate(new Date(ary[0], ary[1], ary[2]))
                    }
                    if (parsed.bank) {
                        setBank(parsed.bank)
                    }
                    if (parsed.bankstatus) {
                        setBankStatus(parsed.bankstatus)
                    }
                    if (parsed.validatestatus) {
                        setValidateStatus(parsed.validatestatus)
                    }
                    setPassParameters(true)
                }
            }

        })()

    }, [])

    useEffect(() => {
        if (passParameters) searchSubmit(1)

    }, [passParameters])

    return (
        <>
            <div className="page-header">
            </div>
            <div className="row">
                <div className="col-md-12">
                    {/* start form */}
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div className="form-group">
                                        <div className="input-group ">
                                            <span className="input-group-text">From Date:</span>
                                            <DatePicker className="form-control" selected={fromDate} onChange={(date) => setFromDate(date)} dateFormat="dd/MM/yyyy" />
                                            <span className="input-group-text"><i className="bi bi-calendar-date"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <span className="input-group-text">To Date:</span>
                                            <DatePicker className="form-control" selected={toDate} onChange={(date) => setToDate(date)} dateFormat="dd/MM/yyyy" />
                                            <span className="input-group-text"><i className="bi bi-calendar-date"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-1">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <select className="form-select" defaultValue={pageSize} onChange={selectPageSize}>
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="10">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="500">500</option>
                                                <option value="1000">1000</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div className="form-group">
                                        {isLoading ?
                                            <button className="btn btn-black btn-border" style={{ "width": "60%" }} type="button">
                                                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                                <span role="status" className="ms-3">Loading...</span>
                                            </button>
                                            :
                                            <button type="button" className="btn btn-primary" style={{ "width": "60%" }} onClick={searchSubmit.bind(this, 1)}> 
                                                <i className="bi bi-search me-2"></i> Search
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div className="form-group">
                                        <div className="input-group ">
                                            <span className="input-group-text">Bank:</span>
                                            <select className="form-select" value={bank} onChange={selectBank}>
                                                <option value="">ALL</option>
                                                {
                                                    bankNameList?.map((item) => {
                                                        return (
                                                            <option key={item.id} value={item.bankcode}>{item.bankname}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div className="form-group">
                                        <div className="input-group ">
                                            <span className="input-group-text">Bank Status:</span>
                                            <select className="form-select" value={bankStatus} onChange={selectBankStatus}>
                                                <option value="">ALL</option>
                                                <option value="NONE">NONE</option>
                                                <option value="CREATED">CREATED</option>
                                                <option value="EXPORTED">EXPORTED</option>
                                                <option value="IMPORTED">IMPORTED</option>
                                                <option value="SUCCESS">SUCCESS</option>
                                                <option value="ERROR">ERROR</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div className="form-group">
                                        {isLoadingExport ?
                                            <button className="btn btn-black btn-border" style={{ "width": "60%" }} type="button">
                                                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                                <span role="status" className="ms-3">Loading...</span>
                                            </button>
                                            :
                                            <button type="button" className="btn btn-black btn-border" style={{ "width": "60%" }} onClick={exportExcel.bind(this)}>
                                                Export to Excel <i className="bi bi-download ms-2"></i>
                                            </button>
                                        }
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div className="form-group">
                                        <div className="input-group ">
                                            <span className="input-group-text">Order Status:</span>
                                            <select className="form-select" value={validateStatus} onChange={selectValidateStatus}>
                                                <option value="">ALL</option>
                                                <option value="NONE">NONE</option>
                                                <option value="PAID">PAID</option>
                                                <option value="NOT_PAID">NOT_PAID</option>
                                                <option value="ERROR">ERROR</option>
                                                <option value="CANCELLED">CANCELLED</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div className="form-group">
                                        <div className="input-group ">
                                            <span className="input-group-text">File ID:</span>
                                            <input type="text" className="form-control" placeholder="Enter File ID" onChange={changeFileId} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div className="form-group">
                                        <button type="button" className="btn btn-black btn-border" style={{ "width": "60%" }} onClick={generateOrderToBankFile.bind(this)}> 
                                            Generate Bank File <i className="bi bi-arrow-right-circle ms-2"></i></button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div className="form-group">
                                        <div className="input-group ">
                                            <span className="input-group-text">ABO No:</span>
                                            <input type="text" className="form-control" placeholder="Enter ABO No." onChange={changeAboNo} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div className="form-group">
                                        <div className="input-group ">
                                            <span className="input-group-text">Order No:</span>
                                            <input type="text" className="form-control" placeholder="Enter Order No." onChange={changeOrderNo} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                    <div className="form-group">
                                        <button type="button" className="btn btn-black btn-border" style={{ "width": "60%" }} onClick={captureOrder.bind(this)}>
                                            Capture Payment <i className="bi bi-arrow-right-circle ms-2"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end form */}
                </div>
            </div>
            {isShowOrderList &&
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-round">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <OrderStatusList data={data} setSelectedOrderList={setSelectedOrderList} />
                                    </div>
                                </div>

                                <Pagination total={total} pageSize={pageSize} currentPage={currentPage} onPageChange={onPageChange} />
                            </div>
                        </div>
                    </div>
                </div>
            }

            {isShowCaptureOrderList &&
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-round">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <CaptureOrderList data={captureOrderList} cancelCaptureOrder={cancelCaptureOrder} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            }

            {isShowGenerateBankFile &&
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-round">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <GenerateBankFile data={generateBankFileList} cancelGenerateBankFile={cancelGenerateBankFile} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className='row'>
                <div className='col-md-12'>
                    <div className="modal fade" id="errorMessageModal" tabIndex="-1" >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <p className="text-danger">{error}</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}