import React, { useState, useRef } from "react"
import { formatDate, formatDateDB, formatDateTime } from "../../components/DateUtil";
import { Modal } from 'bootstrap';
import axios from "axios";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";

export default function BankFileStatusList({ data, searchSubmit }) {

    /* handle import bank file */
    const [selectedFile, setSelectedFile] = useState(null)
    const [selectedBank, setSelectedBank] = useState({ id: '', fileId: '', bankName: '', genDateFrom: '', genDateTo: '' })
    const [errorMessageClass, setErrorMessageClass] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const importBankFileRef = useRef()
    const [isUploadCompleted, setIsUploadCompleted] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    let history = useHistory()

    // export part
    const [exportErrorMessageClass, setExportErrorMessageClass] = useState('')
    const [exportErrorMessage, setExportErrorMessage] = useState('')
    const [isExportLoading, setIsExportLoading] = useState(false)
    const [genDateFrom, setGenDateFrom] = useState(null);
    const [genDateTo, setGenDateTo] = useState(null);
    

    function importFileHandle(id, fileId, bankName, dateFrom, dateTo) {
        console.log('selected bank: ', id, fileId, bankName, dateFrom, dateTo)
        let item = { id: id, fileId: fileId, bankName: bankName, genDateFrom: dateFrom, genDateTo: dateTo }
        setSelectedBank(item)
        setSelectedFile(null)
        setErrorMessage('')
        setIsUploadCompleted(false)
        importBankFileRef.current.value = ''
        let importBankFileForm = new Modal('#importBankFileForm')
        importBankFileForm.show()
    }

    // On file select (from the pop up)
    const onFileChange = (event) => {
        setSelectedFile(event.target.files[0])
        setErrorMessage('')
    }
    // On file upload (click the upload button)
    async function onFileUpload() {
        setIsUploadCompleted(false)
        setIsLoading(true)
        setErrorMessageClass('text-danger')
        setErrorMessage('')

        // check user has selected file
        if (!selectedFile) {
            setIsLoading(false)
            setErrorMessage('No Bank file is selected!')
            return
        }

        // Create an object of formData
        const formData = new FormData();

        // Update the formData object
        formData.append('id', selectedBank.id)
        formData.append('fileId', selectedBank.fileId)
        formData.append('user', localStorage.getItem('name'))
        formData.append(
            "bankFile",
            selectedFile,
            selectedFile.name
        );

        // Details of the uploaded file
        console.log(selectedFile);

        // Request made to the backend api
        // Send formData object
        let token = localStorage.getItem('token')
        await axios.post(process.env.REACT_APP_BASE_URL + "autorenew/v1/upload_bankfile", formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            console.log('response status: ' + JSON.stringify(response.data))
            if (response.data.status === 'success') setErrorMessageClass('text-success')
            setErrorMessage(response.data.message)
            setIsUploadCompleted(true)
        }).catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                console.log('no authorize, redirect to login page')
                history.push(process.env.REACT_APP_LOGIN_URI)
            } else {
                console.log('get upload bank file failed: ' + error.message)
                setErrorMessage(error.message)
            }
        })

        //await new Promise( res => setTimeout(res, 1000) );
        setIsLoading(false)
    };

    /* open export window to confirm */
    async function exportConfirmHandle(id, fileId, bankName, dateFrom, dateTo) {
        console.log('selected bank: ', id, fileId, bankName, dateFrom, dateTo)
        let item = { id: id, fileId: fileId, bankName: bankName, genDateFrom: dateFrom, genDateTo: dateTo }
        setSelectedBank(item)
        setGenDateFrom(new Date(dateFrom))
        if (!dateTo) {
            dateTo = dateFrom
        }
        setGenDateTo(new Date(dateTo))
        setExportErrorMessage('')
        let exportBankFileForm = new Modal('#exportBankFileForm')
        exportBankFileForm.show()
    }
    /* handle export bank file */
    async function exportFileHandle() {
        console.log('export bank file: ', selectedBank.id, selectedBank.fileId, selectedBank.bankName, selectedBank.genDateFrom, selectedBank.genDateTo)
        //alert('export bank file id: ' + id + ', fileId: ' + fileId)
        setIsExportLoading(true)
        setExportErrorMessageClass('text-danger')
        setExportErrorMessage('')

        const formData = {
            id: selectedBank.id,
            fileId: selectedBank.fileId,
            genDateFrom: formatDateDB(genDateFrom),
            genDateTo: formatDateDB(genDateTo),
            user: localStorage.getItem('name')
        }
        // Send formData object
        let token = localStorage.getItem('token')
        await axios.post(process.env.REACT_APP_BASE_URL + "autorenew/v1/download_bankfile", formData, {
            headers: {
                'Authorization': 'Bearer ' + token,
            },
            responseType: 'blob'
        }).then(response => {
            //console.log(JSON.stringify(response))
            console.log(response.headers["content-disposition"])
            let fileName = response.headers["content-disposition"].split("filename=")[1];
            console.log('receive bank file: ' + fileName)
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);

            // close modal
            //let exportBankFileForm = new Modal('#exportBankFileForm')
            //exportBankFileForm.hide()
        }).catch(error => {
            if (error.response.status === 401 || error.response.status === 403) {
                console.log('no authorize, redirect to login page')
                history.push(process.env.REACT_APP_LOGIN_URI)
            } else {
                console.log('get download bank file failed: ' + error.message)
                setExportErrorMessage('Failed to export file')
            }
        })

        //await new Promise( res => setTimeout(res, 1000) );
        setIsExportLoading(false)
    }

    return (
        <>
            <div className="table-responsive table-hover table-sales">
                <table className="table table-striped table-hover align-items-center mb-0">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">No.</th>
                            <th scope="col">File ID</th>
                            <th scope="col">Bank</th>
                            <th scope="col">Gen Date</th>
                            <th scope="col">Gen By</th>
                            <th scope="col" className="text-end">Rec. Num</th>
                            <th scope="col" className="text-end">Amount</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                            <th scope="col">Exported By</th>
                            <th scope="col">Action</th>
                            <th scope="col">Imported By</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data?.map((order, index) => {
                                let statusClass = ''
                                let action = order.action.toLowerCase()
                                let showExportButton = false
                                if (action === 'attention') statusClass = 'badge text-bg-warning'
                                else if (action === 'import') {
                                    showExportButton = true
                                    statusClass = 'badge text-bg-success'
                                } else if (action === 'export') {
                                    showExportButton = true
                                    statusClass = 'badge text-bg-primary'
                                }

                                return (
                                    <tr key={order.id}>
                                        <td>{index+1}</td>
                                        <td>{order.fileid}</td>
                                        <td>{order.bank}</td>
                                        <td>{formatDate(new Date(order.gendatefrom))} - {formatDate(new Date(order.gendateto))}</td>
                                        <td>{order.genby}</td>
                                        <td className="text-end">{order.recnum}</td>
                                        <td className="text-end">{
                                            new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'code' })
                                                .format(order.amount).replace("USD", "").trim()}</td>
                                        <td>{order.status}</td>
                                        <td>{showExportButton &&
                                            <button type="button" onClick={exportConfirmHandle.bind(this, order.id, order.fileid, order.bank, order.gendatefrom, order.gendateto)} className="btn btn-primary" style={{ "--bs-btn-font-size": ".75rem", "--bs-btn-padding-y": ".15rem", "width": "80px" }} title="Export file from system">
                                                Export
                                                <i className="bi bi-arrow-up-right-circle ms-2"></i>
                                            </button>
                                        }
                                        </td>
                                        <td>{order.exportedby}<br />{formatDateTime(new Date(order.lastexport))}</td>
                                        <td>{showExportButton &&
                                            <button type="button" onClick={importFileHandle.bind(this, order.id, order.fileid, order.bank, order.gendatefrom, order.gendateto)} className="btn btn-success" style={{ "--bs-btn-font-size": ".75rem", "--bs-btn-padding-y": ".15rem", "width": "80px" }} title="Import file to system">
                                                <i className="bi bi-arrow-down-right-circle me-2"></i>
                                                Import
                                            </button>
                                        }
                                        </td>
                                        <td>{order.importedby}<br />{formatDateTime(new Date(order.lastimport))}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

            <div className="modal fade" id="importBankFileForm" tabIndex="-1" aria-labelledby="importBankFileLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="importBankFileLabel">Import File: {selectedBank.bankName}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="mb-2 row">
                                    <label for="staticOrderFileId" class="col-sm-3 col-form-label">File Id :</label>
                                    <div class="col-sm-9">
                                        <input type="text" readOnly class="form-control-plaintext" id="staticOrderFileId" value={selectedBank.fileId} />
                                    </div>
                                </div>
                                <div className="mb-2 row">
                                    <label for="staticOrderId" class="col-sm-3 col-form-label">Gen Date :</label>
                                    <div class="col-sm-9 col-form-label">
                                        {formatDate(new Date(selectedBank.genDateFrom))} - {formatDate(new Date(selectedBank.genDateTo))}
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <label for="formFile" class="form-label">Bank file to import</label>
                                    <input ref={importBankFileRef} class="form-control" type="file" id="importBankFile" onChange={onFileChange.bind(this)} />
                                </div>
                                {selectedFile &&
                                    <div className="p-2 bg-body-secondary rounded">
                                        <div className="row">
                                            <label for="staticSelectedFileType" class="col-sm-3 col-form-label">Type :</label>
                                            <div class="col-sm-9">
                                                <input type="text" readOnly class="form-control-plaintext" id="staticSelectedFileType" value={selectedFile.type} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <label for="staticSelectedFileLastModified" class="col-sm-3 col-form-label">Last modified :</label>
                                            <div class="col-sm-9">
                                                <input type="text" readOnly class="form-control-plaintext" id="staticSelectedFileLastModified" value={formatDateTime(selectedFile.lastModifiedDate)} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <label for="staticSelectedFileSize" class="col-sm-3 col-form-label">Size :</label>
                                            <div class="col-sm-9">
                                                <input type="text" readOnly class="form-control-plaintext" id="staticSelectedFileSize" value={selectedFile.size < 1000 ? selectedFile.size : Math.round(selectedFile.size / 1000) + "KB"} />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </form>
                            <div className="mb-3">
                                <p className="mt-4 mb-6 text-sm">
                                    <span className={errorMessageClass}>{errorMessage}</span>
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-dark" data-bs-dismiss="modal" onClick={() => { searchSubmit(1) }}>Close</button>
                            {isLoading ?
                                <button className="btn btn-primary" style={{ width: '120px' }} type="button">
                                    <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                    <span role="status" className="ms-3">Upload</span>
                                </button>
                                : !isUploadCompleted &&
                                <button type="button" className="btn btn-primary" style={{ width: '120px' }} onClick={onFileUpload.bind(this)}>
                                    <i className="bi bi-upload me-2"></i> Upload
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="exportBankFileForm" tabIndex="-1" aria-labelledby="exportBankFileLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exportBankFileLabel">Export File: {selectedBank.bankName}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="mb-2 row">
                                    <div>
                                        <label for="staticOrderId" class="col-form-label">From Date :</label>
                                    </div>
                                    <div class="input-group">
                                        <DatePicker className="form-control" selected={genDateFrom} onChange={(date) => setGenDateFrom(date)} dateFormat="dd/MM/yyyy" />
                                        <span className="input-group-text"><i className="bi bi-calendar-date"></i></span>
                                    </div>
                                </div>
                                <div className="mb-2 row">
                                    <div>
                                        <label for="staticOrderFileId" class="col-form-label">To Date :</label>
                                    </div>
                                    <div class="input-group">
                                        <DatePicker className="form-control" selected={genDateTo} onChange={(date) => setGenDateTo(date)} dateFormat="dd/MM/yyyy" />
                                        <span className="input-group-text"><i className="bi bi-calendar-date"></i></span>
                                    </div>
                                </div>
                            </form>
                            <div className="mb-3">
                                <p className="mt-4 mb-6 text-sm">
                                    <span className={exportErrorMessageClass}>{exportErrorMessage}</span>
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-dark" data-bs-dismiss="modal" onClick={() => { searchSubmit(1) }}>Close</button>
                            {isExportLoading ?
                                <button className="btn btn-primary" style={{ width: '120px' }} type="button">
                                    <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                    <span role="status" className="ms-3">Export</span>
                                </button>
                                : !isUploadCompleted &&
                                <button type="button" className="btn btn-primary" style={{ width: '120px' }} onClick={exportFileHandle.bind(this)}>
                                    <i className="bi bi-upload me-2"></i> Export
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}