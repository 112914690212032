import React, { useState, useRef } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from 'bootstrap';
import { useHistory } from "react-router-dom";
import clientHttp from '../components/ClientHttp';

import UserList from './UserList';
import Pagination from '../components/Pagination';


export default function UserContent() {
    // auto load bank summary when first come
    const [usernameSearch, setUsernameSearch] = useState('')
    const [roleSearch, setRoleSearch] = useState('')
    const [data, setData] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null)
    const [errorMessageClass, setErrorMessageClass] = useState('')
    const [editMessage, setEditMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    // change page
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0)
    const [pageSize, setPageSize] = useState(50);
    const onPageChange = (page) => {
        setCurrentPage(page);
        console.log('select page: ' + page)
        searchSubmit(page)
    }
    let history = useHistory();

    async function searchSubmit(page) {
        setCurrentPage(page);
        setIsLoading(true)
        let isSuccess = false
        // clear data
        setData(null)

        console.log('call search api with parameters:' + currentPage, pageSize)
        const formData = {
            username: usernameSearch,
            role: roleSearch,
            page: page,
            pageSize: pageSize
        }
        let token = localStorage.getItem('token')

        await clientHttp.post('user/v1/user_list', formData, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then(response => {
                console.log('response status: ' + response.status)
                if (response.data.status === 'success') {
                    console.log('display user list')
                    setTotal(response.data.total)
                    setData(response.data.data)
                    isSuccess = true
                } else {
                    console.log('get user_list failed: ' + response.data.message)
                    setErrorMessage(response.data.message)
                }

            }).catch(err => {
                console.log('error: ', err.message)
                if (err.response.status === 401 || err.response.status === 403) {
                    console.log('no authorize, redirect to login page')
                    history.push(process.env.REACT_APP_LOGIN_URI)
                } else {
                    console.log('get user_list failed: ' + err.message)
                    setErrorMessage(err.message)
                }
            })

        if (!isSuccess) {
            let errorMessageModal = new Modal('#errorMessageModal')
            errorMessageModal.show()
        }

        //await new Promise( res => setTimeout(res, 1000) );
        setIsLoading(false)
    }

    const changeUsernameSearch = (e) => { setUsernameSearch(e.target.value) }
    const selectRoleSearch = (e) => { setRoleSearch(e.target.value) }
    const selectPageSize = (e) => { setPageSize(e.target.value) }


    /**
     * Edit user info section
     */
    const userFormRef = useRef(null)
    React.useEffect(() => {
        if (userFormRef.current) {
            userFormRef.userFormModal = new Modal(userFormRef.current, { backdrop: true });
        }
    }, [userFormRef]);

    const [userId, setUserId] = useState('')
    const [username, setUsername] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [role, setRole] = useState('')
    const [additionalRole, setAdditionalRole] = useState('')
    const [userStatus, setUserStatus] = useState('')

    async function editUser(id) {
        console.log('edit user id: ', id)
        let updateResult = false
        setErrorMessageClass('text-danger')
        setErrorMessage('')
        setEditMessage('')

        // load user
        let token = localStorage.getItem('token')
        await clientHttp.get('user/v1/userbyid', {
            params: { userId: id },
            headers: { 'Authorization': 'Bearer ' + token }
        }).then(response => {
            const data = response.data
            console.log('response status: ' + data.status)
            if (data.status === 'success') {
                const user = data.data[0]
                console.log(user)
                setUserId(user.id)
                setUsername(user.username)
                setFirstname(user.firstname)
                setLastname(user.lastname)
                setRole(user.role)
                setAdditionalRole(user.additional_role ? user.additional_role : '')
                setUserStatus(user.status === 'A' ? true : false)

                updateResult = true
            } else {
                console.log('get user_list failed: ' + data.message)
                setErrorMessage('User Id [' + id + '] ' + data.message)
            }
        }).catch(err => {
            console.log('error: ', err.message)
            if (err.response.status === 401 || err.response.status === 403) {
                console.log('no authorize, redirect to login page')
                history.push(process.env.REACT_APP_LOGIN_URI)
            } else {
                console.log('get userbyid failed: ' + err.message)
                setErrorMessage('User Id [' + id + '] ' + err.message)
            }
        })

        console.log(updateResult)
        if (!updateResult) {
            let errorMessageModal = new Modal('#errorMessageModal')
            errorMessageModal.show()
        } else {
            if (userFormRef.userFormModal) userFormRef.userFormModal.show()
        }
    }

    async function addUser() {
        console.log('add new user')
        setUserId(0)
        setUsername('')
        setFirstname('')
        setLastname('')
        setRole('user')
        setAdditionalRole('')
        setUserStatus(true)

        setEditMessage('')
        if (userFormRef.userFormModal) userFormRef.userFormModal.show()
    }

    async function saveUser() {
        console.log('save user id: ', userId)
        setErrorMessageClass('text-danger')
        setEditMessage('')
        let isNewUser = userId === 0

        // load user
        let token = localStorage.getItem('token')
        const formData = {
            userId: userId,
            username: username.toUpperCase(),
            firstname: firstname,
            lastname: lastname,
            role: role,
            additionalRole: additionalRole,
            status: userStatus ? 'A' : 'I'
        }
        console.log('form data')
        console.log(formData)

        await clientHttp.post('user/v1/update', formData, {
            headers: { 'Authorization': 'Bearer ' + token }
        }).then(response => {
            const data = response.data
            console.log('response status: ' + data.status + ', row: ' + data.message)
            if (data.status === 'success') {
                setErrorMessageClass('text-success')
                console.log('update user success')
            } else {
                console.log('update user fail: ' + data.message)
            }

            if (isNewUser) setErrorMessage(data.message)
            else setEditMessage(data.message)
        }).catch(err => {
            console.log('error: ', err.message)
            if (err.response.status === 401 || err.response.status === 403) {
                console.log('no authorize, redirect to login page')
                history.push(process.env.REACT_APP_LOGIN_URI)
            } else {
                console.log('save user failed: ' + err.message)
                if (isNewUser) setErrorMessage(err.message)
                else setEditMessage(err.message)
            }
        })

        console.log('Is new user: ' + isNewUser)
        if (isNewUser) {
            // close form and display result popup
            if (userFormRef.userFormModal) userFormRef.userFormModal.hide()
            let errorMessageModal = new Modal('#errorMessageModal')
            errorMessageModal.show()
        } else {
            // still open form and display result message
        }
    }

    // Delete user section
    const [deleteUsername, setDeleteUsername] = useState(null)
    const [deleteUserId, setDeleteUserId] = useState(null)
    const [deleteUserModal, setDeleteUserModal] = useState(null)
    async function deleteUser(userId, username) {
        console.log('delete user id ' + userId + ', ' + username)
        setDeleteUsername(username)
        setDeleteUserId(userId)

        let deleteModal = new Modal('#deleteUserModal')
        deleteModal.show()
        setDeleteUserModal(deleteModal)
    }
    async function confirmDeleteUserHandle() {
        console.log('confirm delete user id ' + deleteUserId)
        setErrorMessageClass('text-danger')
        setEditMessage('')

        // load user
        let token = localStorage.getItem('token')
        await clientHttp.post('user/v1/deletebyid', { userId: deleteUserId }, {
            headers: { 'Authorization': 'Bearer ' + token }
        }).then(response => {
            const data = response.data
            console.log('response status: ' + data.status + ', row: ' + data.message)

            if (data.status === 'success') {
                setErrorMessageClass('text-success')
                console.log('delete user success')
            } else {
                console.log('delete user fail: ' + data.message)
            }
            setErrorMessage(data.message)
        }).catch(error => {
            console.log('error: ', error.message)
            if (error.response.status === 401 || error.response.status === 403) {
                console.log('no authorize, redirect to login page')
                history.push(process.env.REACT_APP_LOGIN_URI)
            } else {
                console.log('delete user failed: ' + error.message)
                setErrorMessage(data.message)
            }
        })

        // close previous modal
        deleteUserModal.hide()
        // show result
        let errorMessageModal = new Modal('#errorMessageModal')
        errorMessageModal.show()
    }

    return (
        <>
            <div className="page-header">
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <div className="form-group">
                                        <div className="input-group ">
                                            <span className="input-group-text">Username:</span>
                                            <input type="text" name="searchUsername" className="form-control" value={usernameSearch} onChange={changeUsernameSearch} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <div className="form-group">
                                        <div className="input-group ">
                                            <span className="input-group-text">Role:</span>
                                            <select className="form-select" name="searchRole" defaultValue={roleSearch} onChange={selectRoleSearch}>
                                                <option value=''>ALL</option>
                                                <option value='admin'>Admin</option>
                                                <option value='user'>User</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-1 col-md-1 col-sm-1">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <select className="form-select" defaultValue={pageSize} onChange={selectPageSize}>
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="10">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                                <option value="500">500</option>
                                                <option value="1000">1000</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <div className="form-group">
                                        {isLoading ?
                                            <button className="btn btn-black btn-border" style={{ "width": "60%" }} type="button">
                                                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                                <span role="status" className="ms-3">Loading...</span>
                                            </button>
                                            :
                                            <button type="button" className="btn btn-primary" style={{ "width": "60%" }} onClick={searchSubmit.bind(this, 1)}> 
                                                <i className="bi bi-search me-2"></i> Search
                                            </button>
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12">
                                    <div className="form-group">
                                        <button type="button" className="btn btn-black btn-border" style={{ "width": "60%" }} onClick={addUser.bind(this)}>
                                            <i className="bi bi-person-add me-2"></i> Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card card-round">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <UserList data={data} editUser={editUser} deleteUser={deleteUser} />
                                </div>
                            </div>

                            <Pagination total={total} pageSize={pageSize} currentPage={currentPage} onPageChange={onPageChange} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-12'>
                    <div className="modal fade" id="errorMessageModal" tabIndex="-1" >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <p className={errorMessageClass}><span className="h4">{errorMessage}</span></p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => { searchSubmit(1) }}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div ref={userFormRef} className="modal fade" id="editUserForm" tabIndex="-1" aria-labelledby="editUserFormLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="editUserFormLabel">User Form</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="mb-3">
                                    <label className="col-form-label">User Id:</label>
                                    <span className="ms-2">{userId}</span>
                                </div>
                                <div className="mb-3">
                                    <label className="col-form-label">Username:</label>
                                    <input type="text" name="username" className="form-control" value={username} onChange={e => setUsername(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label className="col-form-label">Firstname:</label>
                                    <input type="text" name="firstname" className="form-control" value={firstname} onChange={e => setFirstname(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label className="col-form-label">Lastname:</label>
                                    <input type="text" name="lastname" className="form-control" value={lastname} onChange={e => setLastname(e.target.value)} />
                                </div>
                                <div className="mb-3">
                                    <label className="col-form-label">Role:</label>
                                    <select name="role" value={role} className="form-select" onChange={e => setRole(e.target.value)}>
                                        <option value=''>Select</option>
                                        <option value='admin'>Admin</option>
                                        <option value='user'>User</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label className="col-form-label">Additional Role:</label>
                                    <select name="additionalRole" value={additionalRole} className="form-select" onChange={e => setAdditionalRole(e.target.value)}>
                                        <option value=''>None</option>
                                        <option value='approver'>Approver</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <input className="form-check-input" type="checkbox" checked={userStatus} onChange={e => setUserStatus(e.target.checked)} />
                                    <label className="form-check-label ms-2">Status: {userStatus ? 'Active' : 'Inactive'}</label>
                                </div>
                            </form>
                            <div className="mb-3">
                                <p className="mt-4 mb-6 text-sm">
                                    <span className={errorMessageClass}>{editMessage}</span>
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-dark" data-bs-dismiss="modal" onClick={() => { searchSubmit(1) }}>Close</button>
                            <button type="button" className="btn btn-primary" style={{ width: '120px' }} onClick={() => { saveUser() }}>
                                <i className="bi bi-save me-2"></i> Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row'>
                <div className='col-md-12'>
                    <div className="modal fade" id="deleteUserModal" tabIndex="-1" >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Delete</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <p className="text-dark">Confirm to delete user: {deleteUsername}</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" onClick={confirmDeleteUserHandle.bind(this)}>Confirm to delete</button>
                                    <button type="button" className="btn btn-dark" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}