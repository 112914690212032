import React from "react";

export default function LeftMenu() {
    const pathname = window.location.pathname;
    
    function toggleMenu() {
      const element = window.document.querySelector(".wrapper")
      if (element) {
        element.classList.toggle('sidebar_minimize')
      }
    }

    return (
      <div className="sidebar" data-background-color="dark">
        <div className="sidebar-logo">
          <div className="logo-header" data-background-color="dark">
            <a href="/" className="logo">
              <img
                src="/assets/img/logo-amway-white.svg"
                alt="Amway logo"
                className="navbar-brand"
                height="20"
              />
            </a>
            <div className="nav-toggle">
              <button className="btn btn-toggle toggle-sidebar" onClick={toggleMenu.bind(this)}>
                <i className="gg-menu-right"></i>
              </button>
              <button className="btn btn-toggle sidenav-toggler">
                <i className="gg-menu-left"></i>
              </button>
            </div>
            <button className="topbar-toggler more">
              <i className="gg-more-vertical-alt"></i>
            </button>
          </div>
        </div>
        <div className="sidebar-wrapper scrollbar scrollbar-inner">
          <div className="sidebar-content">
            <ul className="nav nav-secondary">
              <li className="nav-section">
                <span className="sidebar-mini-icon">
                  <i className="bi bi-three-dots"></i>
                </span>
                <h4 className="text-section">Menu</h4>
              </li>
              <li className={pathname === '/autorenew/dashboard' ? 'nav-item active' : 'nav-item'}>
                <a href="/autorenew/dashboard">
                  <i className="bi bi-house-door" style={{ "fontSize": "1.5em" }} title="Dashboard"></i>
                  <p>Dashboard</p>
                </a>
              </li>
              <li className={pathname === '/autorenew/orderstatus' ? 'nav-item active' : 'nav-item'}>
                <a href="/autorenew/orderstatus">
                  <i className="bi bi-layers-fill" style={{ "fontSize": "1.5em" }} title="AutoRenew Orders"></i>
                  <p>AutoRenew Orders</p>
                </a>
              </li>
              <li className={pathname === '/autorenew/bankfilestatus' ? 'nav-item active' : 'nav-item'}>
                <a href="/autorenew/bankfilestatus">
                  <i className="bi bi-list-task" style={{ "fontSize": "1.5em" }} title="Bank File Status"></i>
                  <p>Bank File Status</p>
                </a>
              </li>
              <li className={pathname === '/autorenew/bankfileapproval' ? 'nav-item active' : 'nav-item'}>
                <a href="/autorenew/bankfileapproval">
                  <i className="bi bi-check-lg" style={{ "fontSize": "1.5em" }} title="Bank File Approval"></i>
                  <p>Bank File Approval</p>
                </a>
              </li>
              <li className={pathname === '/user/usermanagement' ? 'nav-item active' : 'nav-item'}>
                <a href="/user/usermanagement">
                  <i className="bi bi-person-gear" style={{ "fontSize": "1.5em" }} title="User Management"></i>
                  <p>User Management</p>
                </a>
              </li>
              <li className={pathname === '/report/salesummary' ? 'nav-item active' : 'nav-item'}>
                <a href="/report/salesummary">
                  <i className="bi bi-file-earmark-text" style={{ "fontSize": "1.5em" }} title="Sale Summary Report"></i>
                  <p>Sale Summary Report</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

    )
  }