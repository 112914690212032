import React, { useState,useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from 'bootstrap';
import { useHistory } from "react-router-dom";
import clientHttp from '../../components/ClientHttp';

import BankSummary from "./BankSummary"

export default function DashboardContent() {
    // auto load bank summary when first come
    const currentDate = new Date();
    const [fromDate, setFromDate] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
    const [toDate, setToDate] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0));
    const [data, setData] = useState(null);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    let history = useHistory()

    async function searchSubmit() {
        console.log('call search api with parameters:' + fromDate, toDate)
        setIsLoading(true)
        let isSuccess = false
        // clear data
        setError('')
        setData(null)

        // set token to header
        const startDate = fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate()
        const endDate = toDate.getFullYear() + '-' + (toDate.getMonth() + 1) + '-' + toDate.getDate()
        const formData = { startDate: startDate, endDate: endDate }
        let token = localStorage.getItem('token')

        await clientHttp.post('autorenew/v1/order_summary', formData, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then(response => {
                console.log('response status: ' + response.status)
                if (response.data.status === 'success') {
                    console.log('display order summary')
                    setData(response.data.data)
                    isSuccess = true
                } else {
                    console.log('get order_summary failed: ' + response.data.message)
                    setError(response.data.message)
                }

            }).catch(err => {
                console.log('error: ', err.message)
                if (err.response.status === 401 || err.response.status === 403) {
                    console.log('no authorize, redirect to login page')
                    history.push(process.env.REACT_APP_LOGIN_URI)
                } else {
                    console.log('get order_summary failed: ' + err.message)
                    setError(err.message)
                }
            })

        if (!isSuccess && error?.length > 0) {
            let errorMessageModal = new Modal('#errorMessageModal')
            errorMessageModal.show()
        }
        //await new Promise( res => setTimeout(res, 1000) );
        setIsLoading(false)
    }

    useEffect(() => {
        // first enter then call search with current month
        searchSubmit()
    }, [])

    return (
        <>
            <div className="page-header">
            </div>
            <div className="row">
                <div className="col-md-12">
                    {/* start form */}
                    <div className="card card-round">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <div className="form-group">
                                        <div className="input-group ">
                                            <span className="input-group-text">From Date:</span>
                                            <DatePicker className="form-control" selected={fromDate} onChange={(date) => setFromDate(date)} dateFormat="dd/MM/yyyy" />
                                            <span className="input-group-text"><i className="bi bi-calendar-date"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <div className="form-group">
                                        <div className="input-group ">
                                            <span className="input-group-text">To Date:</span>
                                            <DatePicker className="form-control" selected={toDate} onChange={(date) => setToDate(date)} dateFormat="dd/MM/yyyy" />
                                            <span className="input-group-text"><i className="bi bi-calendar-date"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12">
                                    <div className="form-group">
                                        {isLoading ?
                                            <button className="btn btn-black btn-border" style={{ "width": "60%" }} type="button">
                                                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                                <span role="status" className="ms-3">Loading...</span>
                                            </button>
                                            :
                                            <button type="button" className="btn btn-primary" style={{ "width": "60%" }} onClick={searchSubmit.bind(this)}> 
                                                <i className="bi bi-search me-2"></i> Search
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end form */}
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <BankSummary fromDate={fromDate} toDate={toDate} data={data} />
                </div>
            </div>

            <div className='row'>
                <div className='col-md-12'>
                    <div className="modal fade" id="errorMessageModal" tabIndex="-1" >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <p className="text-danger">{error}</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}