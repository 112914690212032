import React from "react";

export default function Pagination({ total, pageSize, currentPage, onPageChange }) {
    const MAX_PAGE = 5
    const iPageSize = parseInt(pageSize)
    let pagesCount = Math.ceil(total / iPageSize)
    console.log('[pagination] total: ' + total + ', pagesCount: ' + pagesCount + ', currentPage: ' + currentPage)

    if (total === 0 || pagesCount === 1) return (
        <div className="row mt-3"></div>
    )

    const start = currentPage === 1 ? 0 : (currentPage - 1) * iPageSize
    const end = start + iPageSize
    console.log('[pagination] start = ' + start + ', end = ' + end)

    // if pageCount > MAX_PAGE then display 3 left and 3 right
    let pages = []
    if (pagesCount < MAX_PAGE) {
        pages = Array.from({ length: pagesCount }, (_, i) => i + 1)
    }  else {
        let spage = (currentPage <= 3) ? 1 : currentPage - 2
        if ((spage + MAX_PAGE) > pagesCount) {
            spage = pagesCount - MAX_PAGE + 1
        }

        for (let i=0; i<MAX_PAGE; i++) {
            pages.push(spage+i)
        }
    }
    console.log('[pagination] ' + pages)

    return (
        <div className="row mt-3">
            <div className="col-4 justify-content-start">
                <p className="card-category">Showing {start + 1} to {end} of {total} entries</p>
            </div>
            <div className="col-8 justify-content-end text-end">
                <ul className="pagination" style={{"float":"right"}}>
                    <li className="page-item m-2">Page {currentPage} of {pagesCount}</li>
                    {
                        currentPage > 1 &&
                            <>
                            <li className="page-item"><button className="page-link" onClick={onPageChange.bind(this, 1)}><i class="bi bi-chevron-double-left" style={{"fontSize": "0.75rem"}}></i> First</button></li>
                            <li className="page-item"><button className="page-link" onClick={onPageChange.bind(this, currentPage - 1)}><i className="bi bi-chevron-left" style={{"fontSize": "0.75rem"}}></i> Previous</button></li>
                            </>
                    }
                    {
                        pages.map((page) => {
                            return (
                                <li key={page} className={page === currentPage ? "page-item active" : "page-item"}>
                                    {
                                        page === currentPage ? <button className="page-link" tabIndex="-1" aria-disabled="true">{page}</button>
                                            :
                                            <button className="page-link" onClick={onPageChange.bind(this, page)}>{page}</button>
                                    }
                                </li>
                            )
                        })
                    }
                    {
                        currentPage < pagesCount &&
                        <>
                        <li className="page-item"><button className="page-link" onClick={onPageChange.bind(this, currentPage + 1)}>Next <i class="bi bi-chevron-right" style={{"fontSize": "0.75rem"}}></i></button></li>
                        <li className="page-item"><button className="page-link" onClick={onPageChange.bind(this, pagesCount)}>Last <i className="bi bi-chevron-double-right" style={{"fontSize": "0.75rem"}}></i></button></li>
                        </>
                    }
                </ul>
            </div>
        </div >
    )
}