import React from "react";
import { formatDate } from "../../components/DateUtil";

export default function SaleSummaryData({ reportDate, fromDate, toDate, dateType, data }) {

    console.log('Date: ' + fromDate + ', ' + toDate)

    return (
        <>
            { data &&
            <>
            <div>
                <h5>Report Date: {
                            dateType === 2 ?
                            formatDate(reportDate)
                            :
                            formatDate(fromDate) + ' - ' + formatDate(toDate)
                        }
                </h5>
            </div>
            <div className="card">
                <div className="card-header" style={{ "backgroundColor": "#d6eaf8" }}>
                    <h5>จำนวนออเดอร์</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="card m-2 ms-3" style={{ "width": "18rem", "backgroundColor": "#ebf5fb" }}>
                            <div className="card-body text-center fw-bold">
                                Subject
                            </div>
                        </div>
                        <div className="card m-2 bg-gray-300" style={{ "width": "18rem", "backgroundColor": "#ebf5fb" }}>
                            <div className="card-body text-center fw-bold">
                                EOM
                            </div>
                        </div>
                        <div className="card m-2 bg-gray-300" style={{ "width": "18rem", "backgroundColor": "#ebf5fb" }}>
                            <div className="card-body text-center fw-bold">
                                EPM
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="card m-2 ms-3" style={{ "width": "18rem" }}>
                            <div className="card-body fw-bold">
                                Records
                            </div>
                        </div>
                        <div className="card m-2" style={{ "width": "18rem" }}>
                            <div className="card-body text-end">
                            {new Intl.NumberFormat().format(data.eom_records)}
                            </div>
                        </div>
                        <div className="card m-2" style={{ "width": "18rem" }}>
                            <div className="card-body text-end">
                            {new Intl.NumberFormat().format(data.epm_records)}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="card m-2 ms-3" style={{ "width": "18rem" }}>
                            <div className="card-body fw-bold">
                                Total Paid
                            </div>
                        </div>
                        <div className="card m-2" style={{ "width": "18rem" }}>
                            <div className="card-body text-end">
                            {new Intl.NumberFormat().format(data.eom_totalPaid)}
                            </div>
                        </div>
                        <div className="card m-2" style={{ "width": "18rem" }}>
                            <div className="card-body text-end">
                            {new Intl.NumberFormat().format(data.epm_totalPaid)}
                            </div>
                        </div>
                    </div>
               </div>
            </div>
            <div className="card">
                <div className="card-header" style={{ "backgroundColor": "#d5f5e3" }}>
                    <h5>จำนวนเงินที่ได้รับ</h5>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="card m-2 ms-3" style={{ "width": "18rem", "backgroundColor": "#eafaf1" }}>
                            <div className="card-body text-center fw-bold">
                                Sub Total
                            </div>
                        </div>
                        <div className="card m-2 bg-gray-300" style={{ "width": "18rem", "backgroundColor": "#eafaf1" }}>
                            <div className="card-body text-center fw-bold">
                                Total Tax
                            </div>
                        </div>
                        <div className="card m-2 bg-gray-300" style={{ "width": "18rem", "backgroundColor": "#eafaf1" }}>
                            <div className="card-body text-center fw-bold">
                                Total Price
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="card m-2 ms-3" style={{ "width": "18rem" }}>
                            <div className="card-body text-end">
                            {new Intl.NumberFormat().format(data.subtotal)}
                            </div>
                        </div>
                        <div className="card m-2" style={{ "width": "18rem" }}>
                            <div className="card-body text-end">
                            {new Intl.NumberFormat().format(data.totalTax)}
                            </div>
                        </div>
                        <div className="card m-2" style={{ "width": "18rem" }}>
                            <div className="card-body text-end">
                            {new Intl.NumberFormat().format(data.totalPrice)}
                            </div>
                        </div>
                    </div>
               </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="card m-2 ms-3" style={{ "width": "18rem" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-6 text-start fw-bold">Credit card</div>
                                    <div className="col-sm-6 text-end">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'code' })
                                                .format(data.creditCard).replace("USD", "").trim()}</div>
                                </div>
                            </div>
                        </div>
                        <div className="card m-2" style={{ "width": "18rem" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-6 text-start fw-bold">Cash</div>
                                    <div className="col-sm-6 text-end">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'code' })
                                                .format(data.cash).replace("USD", "").trim()}</div>
                                </div>
                            </div>
                        </div>
                        <div className="card m-2" style={{ "width": "18rem" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-6 text-start fw-bold">Credit Voucher</div>
                                    <div className="col-sm-6 text-end">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'code' })
                                                .format(data.creditVoucher).replace("USD", "").trim()}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="card m-2 ms-3" style={{ "width": "18rem" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-6 text-start fw-bold">E-Banking</div>
                                    <div className="col-sm-6 text-end">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'code' })
                                                .format(data.eBanking).replace("USD", "").trim()}</div>
                                </div>
                            </div>
                        </div>
                        <div className="card m-2" style={{ "width": "18rem" }}>
                            <div className="card-body text-end">
                                <div className="row">
                                    <div className="col-sm-6 text-start fw-bold">QR</div>
                                    <div className="col-sm-6 text-end">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'code' })
                                                .format(data.qr).replace("USD", "").trim()}</div>
                                </div>
                            </div>
                        </div>
                        <div className="card m-2" style={{ "width": "18rem" }}>
                            <div className="card-body text-end">
                                <div className="row">
                                    <div className="col-sm-6 text-start fw-bold">AR</div>
                                    <div className="col-sm-6 text-end">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'code' })
                                                .format(data.ar).replace("USD", "").trim()}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="card m-2 ms-3" style={{ "width": "18rem" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-6 text-start fw-bold">Amplus</div>
                                    <div className="col-sm-6 text-end">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'code' })
                                                .format(data.amplus).replace("USD", "").trim()}</div>
                                </div>
                            </div>
                        </div>
                        <div className="card m-2" style={{ "width": "18rem" }}>
                            <div className="card-body text-end">
                                <div className="row">
                                    <div className="col-sm-6 text-start fw-bold">Staff Entitlement</div>
                                    <div className="col-sm-6 text-end">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'code' })
                                                .format(data.staffEntitlement).replace("USD", "").trim()}</div>
                                </div>
                            </div>
                        </div>
                        <div className="card m-2" style={{ "width": "18rem" }}>
                            <div className="card-body text-end">
                                <div className="row">
                                    <div className="col-sm-6 text-start fw-bold">MPOS</div>
                                    <div className="col-sm-6 text-end">{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencyDisplay: 'code' })
                                                .format(data.mpos).replace("USD", "").trim()}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
            }
        </>
    )
}