import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from 'bootstrap';
import { useHistory } from "react-router-dom";
import clientHttp from '../../components/ClientHttp';

import SaleSummaryData from "./SaleSummaryData";

export default function SaleSummaryReportContent() {
    const currentDate = new Date();
    const [reportDate, setReportDate] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1));
    const [fromDate, setFromDate] = useState(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 1));
    const [toDate, setToDate] = useState(currentDate);
    const [dateType, setDateType] = useState(2)
    const [data, setData] = useState(null);
    const [error, setError] = useState('');
    let history = useHistory()

    const [isSearchLoading1, setIsSearchLoading1] = useState(false)
    const [isSendmailLoading1, setIsSendmailLoading1] = useState(false)
    const [isSearchLoading2, setIsSearchLoading2] = useState(false)
    const [isSendmailLoading2, setIsSendmailLoading2] = useState(false)
    const [isDownloadLoading2, setIsDownloadLoading2] = useState(false)

    async function searchSubmit(type) {
        if (type===1) setIsSearchLoading1(true)
        else setIsSearchLoading2(true)
        setDateType(type)
        let isSuccess = false
        // clear data
        setError('')
        setData(null)


        console.log('call search api with parameters:' + fromDate, toDate)
        const _fromDate = fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate()
        const _toDate = toDate.getFullYear() + '-' + (toDate.getMonth() + 1) + '-' + toDate.getDate()
        const formData = {
            fromDate: _fromDate,
            toDate: _toDate,
            dateType: dateType
        }
        console.log(formData)
        let token = localStorage.getItem('token')
        await clientHttp.post('report/v1/salesummary', formData, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then(response => {
                console.log('response status: ' + response.status)
                if (response.data.status === 'success') {
                    console.log('display sale summmary report')
                    setData(response.data)
                    console.log(response.data)
                    isSuccess = true
                } else {
                    console.log('get sale summary report failed: ' + response.data.message)
                    setError(response.data.message)
                }

            }).catch(err => {
                console.log('error: ', err.message)
                if (err.response.status === 401 || err.response.status === 403) {
                    console.log('no authorize, redirect to login page')
                    history.push(process.env.REACT_APP_LOGIN_URI)
                } else {
                    console.log('get order_status failed: ' + err.message)
                    setError(err.message)
                }
            })

        if (!isSuccess) {
            let errorMessageModal = new Modal('#errorMessageModal')
            errorMessageModal.show()
        }            
        if (type===1) setIsSearchLoading1(false)
        else setIsSearchLoading2(false)
    }

    async function downloadSubmit(type) {
    }

    async function sendmailSubmit(type) {
    }

    useEffect(() => {
        // first enter then call search with current month
        searchSubmit(2)
    }, [])

    return (
        <>
            <div className="page-header">
            </div>
            <div className="row">
                <div className="col-md-12">
                    {/* start form */}
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                                    <div className="form-group">
                                        <div className="input-group ">
                                            <span className="input-group-text">From Date:</span>
                                            <DatePicker className="form-control" selected={fromDate} onChange={(date) => setFromDate(date)} dateFormat="dd/MM/yyyy" />
                                            <span className="input-group-text"><i className="bi bi-calendar-date"></i></span>

                                            <span className="input-group-text ms-3">To Date:</span>
                                            <DatePicker className="form-control" selected={toDate} onChange={(date) => setToDate(date)} dateFormat="dd/MM/yyyy" />
                                            <span className="input-group-text"><i className="bi bi-calendar-date"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12">
                                    <div className="form-group">
                                        {isSearchLoading1 ?
                                            <button className="btn btn-black btn-border btn-primary" style={{ "width": "120px" }} type="button">
                                                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                                <span role="status" className="ms-3">Loading...</span>
                                            </button>
                                            :
                                            <button type="button" className="btn btn-primary" style={{ "width": "120px" }} onClick={searchSubmit.bind(this, 1)}>
                                                <i className="bi bi-search me-2"></i> Search
                                            </button>
                                        }
                                        {isSendmailLoading1 ?
                                            <button className="btn btn-black btn-border ms-3" style={{ "width": "120px" }} type="button">
                                                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                                <span role="status" className="ms-3">Loading...</span>
                                            </button>
                                            :
                                            <button type="button" className="btn btn-black btn-border ms-3" style={{ "width": "120px" }} onClick={sendmailSubmit.bind(this, 1)}>
                                                Send Email <i className="bi bi-send ms-2"></i>
                                            </button>
                                        }
                                    </div>
                                </div>
                                <div><hr/></div>
                            </div>
                            <div className="row">
                                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                                    <div className="form-group">
                                        <div className="input-group ">
                                            <span className="input-group-text">Report Date:</span>
                                            <DatePicker className="form-control" selected={reportDate} onChange={(date) => setReportDate(date)} dateFormat="dd/MM/yyyy" />
                                            <span className="input-group-text"><i className="bi bi-calendar-date"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12">
                                    <div className="form-group">
                                        {isSearchLoading2 ?
                                            <button className="btn btn-black btn-border btn-primary" style={{ "width": "120px" }} type="button">
                                                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                                <span role="status" className="ms-3">Loading...</span>
                                            </button>
                                            :
                                            <button type="button" className="btn btn-primary" style={{ "width": "120px" }} onClick={searchSubmit.bind(this, 2)}>
                                                <i className="bi bi-search me-2"></i> Search
                                            </button>
                                        }
                                        {isDownloadLoading2 ?
                                            <button className="btn btn-black btn-border ms-3" style={{ "width": "120px" }} type="button">
                                                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                                <span role="status" className="ms-3">Loading...</span>
                                            </button>
                                            :
                                            <button type="button" className="btn btn-black btn-border ms-3" style={{ "width": "120px" }} onClick={downloadSubmit.bind(this, 2)}>
                                                Download <i className="bi bi-download ms-2"></i>
                                            </button>
                                        }
                                        {isSendmailLoading2 ?
                                            <button className="btn btn-black btn-border ms-2" style={{ "width": "120px" }} type="button">
                                                <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>
                                                <span role="status" className="ms-3">Loading...</span>
                                            </button>
                                            :
                                            <button type="button" className="btn btn-black btn-border ms-2" style={{ "width": "120px" }} onClick={sendmailSubmit.bind(this, 2)}>
                                                Send Email <i className="bi bi-send ms-2"></i>
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end form */}
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <SaleSummaryData reportDate={reportDate} fromDate={fromDate} toDate={toDate} dateType={dateType} data={data} />
                </div>
            </div>

            <div className='row'>
                <div className='col-md-12'>
                    <div className="modal fade" id="errorMessageModal" tabIndex="-1" >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <p className="text-danger">{error}</p>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}